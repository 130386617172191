import { Chip, Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container";

export interface HistoryEntryProps {
  time: string;
  employer: string;
  title: string;
  description: string;
  tags: string[];
}

export default function HistoryEntry(props: HistoryEntryProps) {
  return (
    <Container disableGutters sx={{ pt: 3 }}>
      <Typography
        variant="overline"
        sx={{ mb: 1, fontWeight: "bold", color: "#5B86F4" }}
      >
        {props.time}
      </Typography>
      <Typography variant="overline" component="div">
        {props.title} @ {props.employer}
      </Typography>
      <Typography align="justify" sx={{ mb: 1 }}>
        {props.description}
      </Typography>
      <Stack direction="row" spacing={1} useFlexGap sx={{ flexWrap: "wrap" }}>
        {props.tags.map((name) => (
          <Chip label={name} color="primary" variant="outlined" size="small" />
        ))}
      </Stack>
    </Container>
  );
}
