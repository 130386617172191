import { Paper, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";

interface SectionCardProps {
  children: ReactNode;
  sxOverrides?: SxProps<Theme>;
}

export default function SectionCard({
  children,
  sxOverrides,
}: SectionCardProps) {
  return (
    <Paper
      elevation={0}
      sx={{ m: 1, mt: 1, p: 2, borderRadius: "10px", maxWidth:"1100px", ...sxOverrides }}
    >
      {children}
    </Paper>
  );
}
