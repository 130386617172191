import { ReactNode, useMemo, useState } from "react";
import { ThemeContext, ThemeContextType } from "./ThemeContext";
import { DarkTheme, LightTheme } from "./Theme";
import { Theme } from "@mui/material";

interface ThemeContextProviderProps {
  children: ReactNode;
}

export const ThemeContextProvider = ({
  children,
}: ThemeContextProviderProps) => {
  const [isDarkMode, setIsDarkMode] = useState(true);

  const theme = useMemo<Theme>(() => {
    return isDarkMode ? DarkTheme : LightTheme;
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode((prev) => {
      return !prev;
    });
  };

  const contextValue: ThemeContextType = {
    isDarkMode,
    toggleDarkMode,
    theme,
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};
