import Image from "../ui/Image";
import SectionCard from "../ui/SectionCard";

export default function TechStackCard() {
  const iconsSize = 100;
  const techStackIcons = [
    "/assets/techstack/csharp.svg",
    "/assets/techstack/flutter.svg",
    "/assets/techstack/java.svg",
    "/assets/techstack/reactjs.png",
    "/assets/techstack/typescript.svg",
    "/assets/techstack/javascript.svg"
  ];
  return (
    <SectionCard
      sxOverrides={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {techStackIcons.map((icon) => (
        <Image
          key={Math.random()}
          source={icon}
          size={iconsSize}
          sxOverrides={{ p: 1 }}
        />
      ))}
    </SectionCard>
  );
}
