import { createTheme } from "@mui/material";

export const DarkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#111318",
      paper: "#16181D"
    },
    primary: {
      main: "#5C87F6",
    },
    secondary: {
      main: "#ECC8AE",
    },
    common: {
      black: "#373737",
      white: "#3F3F3F",
    },
  },
  typography: {
    body1: {
      color: "#8994AE"
    },
    h6: {
      color: "#ACB5C7",
    }
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#8994AE",
          fontFamily: "sans-serif",
        }
      }
    }
  },
});

export const LightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#467054",
    },
    secondary: {
      main: "#66564A",
    },
    common: {
      black: "#BFC2C5",
      white: "#e4e4e4"
    },
  },
  typography: {
    h6: {
      color: "#909090",
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#909090",
          fontFamily: "sans-serif",
        }
      }
    }
  },
});
