import { Typography } from "@mui/material";
import SectionCard from "../ui/SectionCard";

export default function AboutMeCard() {
  return (
    <SectionCard
      sxOverrides={{
        p: 5,
      }}
    >
      <Typography variant="overline" sx={{ mb: 1, fontWeight: "bold" }}>
        About Me
      </Typography>
      <Typography align="justify">
        I am a passionate software developer who considers programming both a
        profession and a hobby. While my main focus is on backend development, I
        enjoy gaining a full-stack perspective by tackling frontend tasks as
        well. I continuously enhance my skills through side projects and embrace
        diverse challenges, including collaborating in international teams.
      </Typography>
    </SectionCard>
  );
}
