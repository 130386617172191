import { Container, CssBaseline, ThemeProvider } from "@mui/material";
import ProfileCard from "./components/profile/ProfileCard";
import { useTheme } from "./theme/ThemeContext";
import TechStackCard from "./components/techstack/TechStackCard";
import SocialCard from "./components/social/SocialCard";
import AboutMeCard from "./components/about/AboutMeCard";
import CvCard from "./components/cv/CvCard";

function App() {
  const themeContext = useTheme();
  
  return (
    <ThemeProvider theme={themeContext.theme}>
      <CssBaseline />
      <Container>
        <ProfileCard />
        <AboutMeCard />
        <CvCard/>
        <TechStackCard />
        <SocialCard />
      </Container>
    </ThemeProvider>
  );
}

export default App;
