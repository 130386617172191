import { Link, Stack } from "@mui/material";

const socials = new Map<string, string>([
  ["LinkedIn", "https://www.linkedin.com/in/marco-schlesier-343042279/"],
  ["Xing", "https://www.xing.com/profile/Marco_Schlesier/cv"],
  ["GitHub", "https://github.com/drunkensponge"],
]);

export default function SocialCard() {
  return (
    <Stack direction="row" justifyContent="center" spacing={2} sx={{p: 3}}>
      {[...socials.entries()].map(([name, link]) => (
        <Link href={link} sx={{ textAlign: "center", textDecoration: "none" }}>
          {name}
        </Link>
      ))}
    </Stack>
  );
}
