import SectionCard from "../ui/SectionCard";
import history from "./history/history.json";
import HistoryEntry, { HistoryEntryProps } from "./history/HistoryEntry";

export default function CvCard() {
  return (
    <SectionCard sxOverrides={{ p: 5, pt: 2 }}>
      {history.map((entry: HistoryEntryProps, index) => (
        <HistoryEntry key={index} {...entry} />
      ))}
    </SectionCard>
  );
}
