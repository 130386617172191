import { Theme } from "@mui/material";
import { createContext, useContext } from "react";

export interface ThemeContextType {
  isDarkMode: boolean;
  toggleDarkMode(): void;
  theme: Theme;
}

export const ThemeContext = createContext<ThemeContextType>({
  isDarkMode: true,
} as ThemeContextType);

export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (context == null) {
      throw new Error("Context for theme is null!");
    }

    return context;
};