import { Box, SxProps, Theme } from "@mui/material";

interface ImageProps {
  source: string;
  alt?: string;
  size: number;
  sxOverrides?: SxProps<Theme>;
}

export default function Image({
  source,
  alt,
  size,
  sxOverrides,
}: ImageProps) {
  return (
    <Box
      component="img"
      src={source}
      alt={alt}
      sx={{
        height: size,
        width: size,
        maxHeight: { xs: size, md: size },
        maxWidth: { xs: size, md: size },
        ...sxOverrides,
      }}
    />
  );
}
